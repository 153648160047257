.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Lato', sans-serif;
}

/* Or, you can target specific elements */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
}

p, span, div {
  font-family: 'Lato', sans-serif;
}




/*** Ant Override Start ***/


.ant-menu-light .ant-menu-item-selected {
  background-color: #FFDDCC !important;
  color: #F3661F !important;
}

/* .ant-menu-light .ant-menu-item-active {
  color: #fa8c16 !important;
} */

.ant-btn-primary {
  background-color: #F3661F !important;
  border-color: #F3661F !important;
}
.ant-btn-primary:hover {
  background-color: #F3661F !important;
  border-color: #F3661F !important;
}
.ant-btn-default {
  border-color: #F3661F !important;
  color: #F3661F !important;
}
.ant-input-outlined:focus-within, .ant-input-outlined:hover {
  border-color: #F3661F !important;
}

.ant-btn-dashed {
  border-color: #F3661F !important;
  color: #F3661F !important;
}
.ant-input:focus {
  box-shadow: none !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent !important;
}
/*** Ant Override End ***/

/*** Custom Class antd override Start ***/

.lesson-card .ant-card-body {
  padding: 10px 24px !important;
}

/*** Custom Class antd override End ***/

.create-assessment-button {
  background-color:#4285F4 !important;
  color: white !important;
  border: #4285F4 !important;
}

